import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      '& > div': {
        minWidth: '150px',
        width: 'auto',
      },
    },
  })
);

export default useStyles;
