import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    loadingView: {
      flex: 1,
      alignSelf: 'center',
    },
  })
);
