import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    welcomeClass: {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '40px',
    },
    titleClass: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(10),
      '& hr': {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.divider,
        minWidth: 'auto',
      },
    },
    containerClass: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '468px',
      minWidth: '800px',
    },
  })
);
