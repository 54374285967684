import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import useStyles from './Router.style';
import CrisisManagerPageView from './pages/CrisisManagerPage/CrisisManagerPageView';
import OngoingCrisisPage from './pages/OngoingCrisisPage/OngoingCrisisPage';
import OngoingCrisisSubPage from './pages/OngoingCrisisPage/OngoingCrisisSubPage/OngoingCrisisSubPage';
import OperationsAutomationPage from './pages/OperationsAutomationPage/OperationsAutomationPage';
import RunbookAutomationPage from './pages/RunbookAutomationPage/RunbookAutomationPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';

export const BASE_URL = '/';
export const CRISISMANAGER_URL = `${BASE_URL}/crisis-manager`;
export const OPERATIONS_AUTOMATION_URL = `${BASE_URL}/operations-automation`;
export const RUNBOOK_AUTOMATION_URL = `${BASE_URL}/runbook-automation`;
export const ONGOING_CRISIS_URL = `${BASE_URL}/ongoing-crisis`;
export const ONGOING_CRISIS_SUBPAGE_URL = `${BASE_URL}/ongoing-crisis/:issueKeyId`;

const Router = (): JSX.Element => {
  const { loadingView } = useStyles();

  return (
    <BrowserRouter basename="/bees-crisis-management">
      <Suspense
        fallback={
          <div className={loadingView} data-testid="transition-spinner">
            <Spinner show dataTest="transition-spinner" />
          </div>
        }
      >
        <Routes>
          <Route path={CRISISMANAGER_URL} element={<CrisisManagerPageView />} />
          <Route path={OPERATIONS_AUTOMATION_URL} element={<OperationsAutomationPage />} />
          <Route path={RUNBOOK_AUTOMATION_URL} element={<RunbookAutomationPage />} />
          <Route path={ONGOING_CRISIS_URL} element={<OngoingCrisisPage />} />
          <Route path={ONGOING_CRISIS_SUBPAGE_URL} element={<OngoingCrisisSubPage />} />
          <Route path={BASE_URL} element={<WelcomePage />} />
          <Route path="/" />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
