import { KeyboardEventHandler } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

const MultiSelect = (props) => {
  const components = {
    DropdownIndicator: null,
  };

  interface Option {
    readonly label: string;
    readonly value: string;
  }

  // istanbul ignore next
  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const handleChange = (value: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
    props.setValue(value);
  };

  const handleInputChange = (inputValue: string) => {
    props.setInputValue(inputValue);
  };
  // istanbul ignore next
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!props.inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (props.value.map((v) => v.label).includes(props.inputValue)) {
          props.setInputValue('');
        } else {
          props.setInputValue('');
          props.setValue([...props.value, createOption(props.inputValue)]);
        }

        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      id={props.id}
      instanceId={props.id}
      className="w-100"
      components={components}
      inputValue={props.inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
};

export default MultiSelect;
