/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import getApiHost from '../services/host/HostService';

export interface IRequest {
  url: string;
  body?: any;
}

const BFF_API_URL = getApiHost();

const initialAxios = Axios.create();

const authentication = useAuthenticationService();

const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(initialAxios, {
  headers: [
    {
      country: userCountry || '',
      noorders: localStorage.getItem('noOrders') || '',
      Authorization: authentication.getAuthHeader(),
      'Content-Type': 'application/json',
    },
  ],
});

const Api = {
  post: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.post(url, body).catch((error) => ({
      hasError: true,
      ...error,
    })),

  delete: ({ url }: IRequest): Promise<any> =>
    initialAxios.delete(`${BFF_API_URL}${url}`).catch((error) => ({ hasError: true, ...error })),

  get: ({ url }: IRequest): Promise<any> =>
    initialAxios.get(`${BFF_API_URL}${url}`).catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.put(`${BFF_API_URL}${url}`, body).catch((error) => ({
      hasError: true,
      ...error,
    })),

  patch: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.patch(`${BFF_API_URL}${url}`, body).catch((error) => ({
      hasError: true,
      ...error,
    })),
};

export default Api;
