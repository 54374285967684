import { IOngoingCrisisDetails } from '../interfaces/IOngoingCrisisDetails';

export const tableDataMock: Array<IOngoingCrisisDetails> = [
  {
    ticketNumber: 'XXX1',
    ticketSummary: 'is simply dummy text of the printing',
    priority: 'high',
    status: 'investigating',
    affectedCountry: ['Brazil', 'Argentina'],
    businessModels: ['BBB', 'B2B'],
    creationDateHour: '04/11/23 12:00',
    followUpDateHour: '06/11/23 12:30',
    assignee: 'Matheus',
  },
  {
    ticketNumber: 'XXX2',
    ticketSummary: 'i am the second ticket here',
    priority: 'low',
    status: 'identified',
    affectedCountry: ['Brazil', 'Argentina'],
    businessModels: ['C2C', 'C2B'],
    creationDateHour: '01/02/23 08:00',
    followUpDateHour: '03/03/23 09:30',
    assignee: 'Silva',
  },
  {
    ticketNumber: 'XXX3',
    ticketSummary: 'this is the thir ticket',
    priority: 'medium',
    status: 'monitoring',
    affectedCountry: ['Brazil', 'Argentina'],
    businessModels: ['CCC', 'B2B'],
    creationDateHour: '31/10/23 10:00',
    followUpDateHour: '31/10/23 11:30',
    assignee: 'Santos',
  },
  {
    ticketNumber: 'XXX4',
    ticketSummary: 'ticket number 4',
    priority: 'high',
    status: 'resolved',
    affectedCountry: ['Brazil', 'Argentina'],
    businessModels: ['B2C', 'B2B'],
    creationDateHour: '30/10/23 12:33',
    followUpDateHour: '18/11/23 15:30',
    assignee: 'Albuquerque',
  },
];

export const columnsMock = [
  {
    Header: 'Ticket Number',
    accessor: 'ticketNumber',
    style: {
      width: '10%',
    },
    disableSortBy: true,
  },
  {
    Header: 'Summary',
    accessor: 'ticketSummary',
    style: {
      width: '30%',
    },
    disableSortBy: true,
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    style: {
      width: '10%',
    },
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    style: {
      width: '10%',
    },
    disableSortBy: true,
  },
  {
    Header: 'Affected Country',
    accessor: 'affectedCountry',
    customRender: (value) => (value ? value.join(', ') : ''),
    style: {
      width: '10%',
    },
    disableSortBy: true,
  },
  {
    Header: 'Business Models',
    accessor: 'businessModels',
    customRender: (value) => (value ? value.join(', ') : ''),
    style: {
      width: '10%',
    },
    disableSortBy: true,
  },
  {
    Header: 'Creation Date',
    accessor: 'creationDateHour',
    style: {
      width: '10%',
    },
    disableSortBy: true,
  },
  {
    Header: 'Assignee',
    accessor: 'assignee',
    style: {
      width: '20%',
    },
    disableSortBy: true,
  },
];
