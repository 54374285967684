import { createStyles, makeStyles } from '@material-ui/core';
// istanbul ignore next
export default makeStyles((theme) =>
  createStyles({
    cardTitleText: {
      display: 'flex',
      justifyContent: 'center',
      width: '85%',
      marginBottom: '50px',
      textAlign: 'center',
    },
    welcomeClass: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      minWidth: '750px',
      maxWidth: '900px',
    },
    titleClass: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(10),
      '& hr': {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.divider,
        minWidth: 'auto',
      },
    },
    containerClass: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '468px',
      minWidth: '1000px',
    },

    listClass: {
      width: '750px',
      marginTop: '60px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',
      textAlign: 'center',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '30px',
        marginRigth: '10px',
        width: '100%',
      },
    },
    listItemClass: {
      lineHeight: '20px',
      fontWeight: 500,
      fontSize: '15px',
      '& [class*="business-model-name-span"]': {
        marginLeft: '10px',
        fontWeight: 'normal',
        color: 'rgba(0,0,0,0.9)',
      },
    },
    listItemTextClass: {
      lineHeight: '20px',
      fontWeight: 500,
      fontSize: '15px',
      gap: '20px',
      '& > div': {
        display: 'flex',
        gap: '20px',
      },
    },
  })
);
