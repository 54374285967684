import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    formControlClass: {
      '&.disabledClass, &.disabledClass label': {
        opacity: 0.6,
      },
      '& > div': {
        backgroundColor: 'white',
        '& img': {
          width: '25px',
        },
      },
    },
    horizontalSelectClass: {
      flexDirection: 'row',
      alignItems: 'center',
      '& label': {
        margin: 0,
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(1),
      },
      '& > div': {
        width: '100%',
      },
    },
    placeholderClass: {
      display: 'none',
    },
    optionalClass: {
      color: theme.palette.text.secondary,
    },
    labelClass: {
      display: 'flex',
      minHeight: '20px',
      alignItems: 'center',
    },
    iconOption: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      '& img': {
        width: 26,
      },
    },
    loadingClass: {
      position: 'absolute',
      right: '10px',
    },
  })
);
