import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    navigateBarStyle: {
      marginLeft: theme.spacing(10),
      marginTop: theme.spacing(1.5),
    },
  })
);
