import { IBusinessModel } from '../interfaces/IBusinessModel';

export const businessModelMock = [
  {
    id: '1',
    name: 'ABI Product (1P1L/1P3L)',
  },
  {
    id: '2',
    name: 'SameStore (3P3L)',
  },
  {
    id: '3',
    name: 'StoreFront (3P3L)',
  },
  {
    id: '4',
    name: 'All',
  },
] as IBusinessModel[];
