export var getUrl = (): string => {
  return document.URL;
};

export const getApiHost = (): string | undefined => {
  if (getUrl().includes('localhost')) {
    return process.env.API_HOST_LOCAL;
  } else if (getUrl().includes('one-uat')) {
    return process.env.API_HOST_UAT;
  } else return process.env.API_HOST;

  return process.env.API_HOST;
};

export default getApiHost;
