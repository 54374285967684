import { ThemeOptions } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
// import DejaVuSansMono from '../assets/fonts/DejaVuSansMono.ttf';
import palette, { colors } from './palette';

const theme = createTheme();

const dejaVuSansMono = {
  fontFamily: 'DejaVu Sans Mono',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  // src: `
  //   local('DejaVuSansMono'),
  //    url(${DejaVuSansMono}) format('truetype')
  // `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
// istanbul ignore next
export default {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [dejaVuSansMono],
      code: {
        fontFamily:
          'DejaVu Sans Mono, Andale Mono, Lucida Console, Courier New, Courier, monospace',
      },
    },
  },
  MuiTab: {
    root: {
      [theme.breakpoints.down('xl')]: {
        minWidth: 'auto',
      },
    },
  },
  MuiLink: {
    root: {
      color: colors.blueLink,
    },
  },
  MuiCard: {
    root: {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    },
  },
  MuiButton: {
    root: {
      whiteSpace: 'nowrap',
      minWidth: 'auto',
    },
    outlined: {
      border: '1px solid #000',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      '&:hover': {
        background: '#EEEEEE',
      },
      '&$disabled': {
        border: '1px solid #DFDEDE',
        color: palette?.text?.hint,
        background: '#DFDEDE',
        boxShadow: 'none',
      },
    },
    contained: {
      '&$disabled': {
        color: palette?.text?.hint,
        background: '#DFDEDE',
        boxShadow: 'none',
      },
    },
  },
  MuiSelect: {
    outlined: {
      padding: '10.5px 14px',
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: '0.875rem',
      fontWeight: 500,
      marginBottom: '5px',
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        color: palette?.text?.hint,
      },
    },
  },
  MuiOutlinedInput: {
    adornedEnd: {
      paddingRight: '0',
    },
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      marginTop: '4px',
      marginBottom: '0',
      '& p': {
        fontWeight: 500,
        fontSize: '0.875rem',
      },
    },
    daysHeader: {
      borderBottom: '1px solid #B9BCC1',
      borderTop: '1px solid #B9BCC1',
      padding: '8px 0',
      maxHeight: 'auto',
      '& span': {
        color: colors.black,
        fontSize: '0.875rem',
        margin: '0 3px',
      },
    },
  },
  MuiPickersDay: {
    day: {
      height: '30px',
      width: '30px',
      margin: '3px 6px',
      fontSize: '0.875rem',
    },
    hidden: {
      opacity: 1,
      color: palette?.text?.hint,
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '4px',
      marginBottom: '4px',
    },
  },
  MuiFormHelperText: {
    root: {
      marginBottom: '4px',
      marginTop: '0',
    },
  },
  MuiMenuItem: {
    root: {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.75rem',
    },
    popper: {
      marginTop: '-8px',
      minHeight: 'auto',
    },
  },
  MuiAlert: {
    root: {
      borderRadius: '8px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.15)',
    },
    message: {
      opacity: 0.8,
      whiteSpace: 'pre-wrap',
      height: '100%',
    },
  },
  MuiSwitch: {
    colorSecondary: {
      '&$checked': {
        color: colors.blueLink,
      },
      '&$checked + $track': {
        backgroundColor: colors.blueLink,
      },
    },
    track: {
      opacity: 0.2,
    },
  },
  MuiBreadcrumbs: {
    separator: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: colors.gray,
      opacity: 0.8,
    },
  },
} as ThemeOptions['overrides'];
