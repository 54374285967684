import {
  Box,
  CircularProgress,
  Fade,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React from 'react';
import { InfoTooltip } from 'supplier-portal-shared-components';
import { formatMessage } from '../../i18n/formatters';
import useStyles from '../SimpleSelect/SimpleSelectView.styles';

export interface OptionsItem {
  icon?: string;
  label: string;
  value: string;
}

export interface SimpleSelectViewProps extends SelectProps {
  label?: string;
  selectedValue: string;
  options: OptionsItem[];
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  optional?: boolean;
  isHorizontal?: boolean;
  handleChange: SelectInputProps['onChange'];
  tooltip?: string;
  loading?: boolean;
  canUpload?: boolean;
}

function SimpleSelectView({
  label,
  selectedValue,
  options,
  id,
  name,
  placeholder,
  className,
  handleChange,
  optional = false,
  isHorizontal = false,
  tooltip,
  required,
  loading,
  canUpload,
  ...allProps
}: SimpleSelectViewProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const {
    formControlClass,
    placeholderClass,
    optionalClass,
    iconOption,
    horizontalSelectClass,
    labelClass,
    loadingClass,
  } = useStyles();

  const handleOnOpen = () => {
    setIsOpen(true);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const loadingProps = () => {
    if (loading) {
      return {
        IconComponent: () => (
          <CircularProgress data-testid={`${id}-loading`} className={loadingClass} size={15} />
        ),
      };
    }
    return {};
  };

  return (
    <FormControl
      className={`
        ${formControlClass} 
        ${className || ''} 
        ${(isHorizontal && horizontalSelectClass) || ''} 
        ${((allProps.disabled || loading) && 'disabledClass') || ''}
      `}
    >
      {label && (
        <FormLabel id={`${id}-label`} className={labelClass}>
          {!required ? label : `${label}*`}
          {optional && (
            <span className={optionalClass}>
              &nbsp;{formatMessage({ id: 'Components.OPTIONAL_LABEL' })}
            </span>
          )}
          {tooltip && <InfoTooltip placement="right" infoMessage={tooltip} />}
        </FormLabel>
      )}
      <Select
        {...allProps}
        labelId={`${id}-label`}
        id={id}
        name={name}
        value={selectedValue}
        open={isOpen}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        onChange={handleChange}
        variant="outlined"
        displayEmpty
        disabled={allProps.disabled || loading}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          elevation: 4,
          TransitionComponent: Fade,
        }}
        {...loadingProps()}
      >
        {placeholder && (
          <MenuItem key={placeholder} value="" className={placeholderClass}>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.icon ? (
                <Box className={iconOption}>
                  <img src={option.icon} alt={option.label} />
                  {option.label}
                </Box>
              ) : (
                option.label
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default SimpleSelectView;
