import React from 'react';
import availableProducts from '../../consts/availableProducts';
import UserInfoContext from '../../context/UserInfoContext';
import { SimpleSelectViewProps } from '../SimpleSelect/SimpleSelectView';
import ProductSelectView from './ProductSelectView';

function ProductSelect(): JSX.Element {
  const { selectedProduct, products, setUserInfoContext } = React.useContext(UserInfoContext);
  const [selectedValue, setSelectedValue] = React.useState<string>('');
  const [productSelectOptions, setProductSelectOptions] = React.useState<
    SimpleSelectViewProps['options']
  >([]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown; name?: string }>) => {
    const { value } = event.target;
    const foundProduct = availableProducts.find((product) => value === product.id);
    setSelectedValue(value as string);
    setUserInfoContext({ ...UserInfoContext, selectedProduct: foundProduct });
  };

  React.useEffect(() => {
    const productSelectNewOptions = availableProducts.map((product) => {
      return {
        label: product.name,
        value: product.id,
      };
    });
    setProductSelectOptions(productSelectNewOptions);
    setSelectedValue(selectedProduct?.id || '');
  }, [selectedProduct]);

  return (
    <ProductSelectView
      ProductSelectOptions={productSelectOptions}
      onSelectProduct={handleSelectChange}
      selectedValue={selectedValue}
    />
  );
}

export default ProductSelect;
