import arFlag from '../assets/flags/ar.svg';
import brFlag from '../assets/flags/br.svg';
import caFlag from '../assets/flags/ca.svg';
import coFlag from '../assets/flags/co.svg';
import doFlag from '../assets/flags/do.svg';
import ecFlag from '../assets/flags/ec.svg';
import gbFlag from '../assets/flags/gb.svg';
import hnFlag from '../assets/flags/hn.svg';
import mxFlag from '../assets/flags/mx.svg';
import paFlag from '../assets/flags/pa.svg';
import peFlag from '../assets/flags/pe.svg';
import pyFlag from '../assets/flags/py.svg';
import skFlag from '../assets/flags/sk.svg';
import tzFlag from '../assets/flags/tz.svg';
import ugFlag from '../assets/flags/ug.svg';
import usFlag from '../assets/flags/us.svg';
import uyFlag from '../assets/flags/uy.svg';
import zaFlag from '../assets/flags/za.svg';

export default [
  {
    label: 'Argentina',
    value: 'AR',
    icon: arFlag,
  },
  {
    label: 'Brazil',
    value: 'BR',
    icon: brFlag,
  },
  {
    label: 'Canada',
    value: 'CA',
    icon: caFlag,
  },
  {
    label: 'Colombia',
    value: 'CO',
    icon: coFlag,
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
    icon: doFlag,
  },
  {
    label: 'Ecuador',
    value: 'EC',
    icon: ecFlag,
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    icon: gbFlag,
  },
  {
    label: 'Honduras',
    value: 'hn',
    icon: hnFlag,
  },
  {
    label: 'Mexico',
    value: 'MX',
    icon: mxFlag,
  },
  {
    label: 'Panama',
    value: 'PA',
    icon: paFlag,
  },
  {
    label: 'Peru',
    value: 'PE',
    icon: peFlag,
  },
  {
    label: 'Paraguay',
    value: 'PY',
    icon: pyFlag,
  },
  {
    label: 'Slovakia',
    value: 'SK',
    icon: skFlag,
  },
  {
    label: 'Uganda',
    value: 'UG',
    icon: ugFlag,
  },
  {
    label: 'Uruguay',
    value: 'UY',
    icon: uyFlag,
  },
  {
    label: 'United States',
    value: 'US',
    icon: usFlag,
  },
  {
    label: 'Tanzania',
    value: 'TZ',
    icon: tzFlag,
  },
  {
    label: 'South Africa',
    value: 'ZA',
    icon: zaFlag,
  },
];
