import { ICrisisManager } from '../interfaces/ICrisisManager';

export const crisisManagerMock = [
  {
    name: 'Dominican Republic',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-do',
    businessFlows: ['Authentication', 'Catalog', 'Combos & Promotions', 'Cart', 'Checkout'],
    product: 'BEES Customer',
  },
  {
    name: 'Argentina',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-ar',
    businessFlows: ['Authentication', 'Catalog', 'Combos & Promotions', 'Cart', 'Checkout'],
    product: 'BEES Customer',
  },
  {
    name: 'Brazil',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-br',
    businessFlows: ['Authentication', 'Catalog', 'Combos & Promotions', 'Cart', 'Checkout'],
    product: 'BEES Customer',
  },
  {
    name: 'Colombia',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-copec',
    businessFlows: ['Authentication', 'Catalog'],
    product: 'BEES Customer',
  },
  {
    name: 'Ecuador',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-copec',
    businessFlows: ['Authentication', 'Catalog'],
    product: 'BEES Customer',
  },
  {
    name: 'Mexico',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-mx',
    businessFlows: ['Authentication', 'Catalog'],
    product: 'BEES Customer',
  },
  {
    name: 'Panama',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-pa',
    businessFlows: ['Authentication', 'Catalog'],
    product: 'BEES Customer',
  },
  {
    name: 'Paraguay',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-py',
    businessFlows: ['Authentication', 'Catalog'],
    product: 'BEES Customer',
  },
  {
    name: 'South Africa',
    componentId: 'xxxxxxx',
    slackChannel: 'ops-customer-za',
    businessFlows: ['Authentication', 'Catalog'],
    product: 'BEES Customer',
  },
] as ICrisisManager[];
