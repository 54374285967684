import { Button } from '@hexa-ui/components';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Spinner } from 'supplier-portal-shared-components';
import Api from '../../../Api/Api';
import { handleErrorMessage } from '../../../Utils/handleError';
import { formatIssueTitle } from '../../../Utils/handleFormatOngoingCrisisSubpageTitle';
import PageContainer from '../../../components/PageContainer/PageContainer';
import SideMenuComponent from '../../../components/SideMenuComponent/SideMenuComponent';
import { Toast } from '../../../components/Toast/Toast';
import { ICrisisFullDetailed } from '../../../interfaces/IOngoingCrisisDetails';
import CrisisDetailsCard from '../CrisisDetailsCard/CrisisDetailsCard';
import useStyles from './OngoingCrisisSubPage.style';

const OngoingCrisisSubPage = (): JSX.Element => {
  const { titleClass, containerClass } = useStyles();
  const [formattedOngoingCrisis, setFormattedOngoingCrisis] = React.useState<ICrisisFullDetailed>();
  const [currentError, setCurrentError] = React.useState({ message: '' });
  const [isOpenToast, setIsOpenToast] = React.useState<boolean>(false);

  const navigate = useNavigate();

  let { issueKeyId } = useParams();

  async function getOngoingCrisisDetails() {
    const { data, response: errorResponse } = await Api.get({
      url: `crisis/ongoing/${issueKeyId}`,
    });

    if (data && !errorResponse) {
      setFormattedOngoingCrisis(data);
    } else {
      const errorMessage = handleErrorMessage(errorResponse.data.statusCode);
      setCurrentError({ message: errorMessage });
      setIsOpenToast(true);
      hideToast();
    }
  }

  React.useEffect(() => {
    getOngoingCrisisDetails();
  }, []);

  function hideToast() {
    setTimeout(() => {
      setIsOpenToast(false);
      navigate('/');
    }, 3000);
  }

  function showToast(currentError: any) {
    if (currentError && currentError.message.length > 0) {
      return <Toast message={currentError.message} open={isOpenToast} type={'error'} />;
    }
    return null;
  }

  return (
    <Container data-test="data-control-page-component">
      {!formattedOngoingCrisis && <Spinner show dataTest="transition-spinner" />}
      <Typography variant="h2" className={titleClass}>
        {formatIssueTitle(issueKeyId, formattedOngoingCrisis)}
      </Typography>
      <Box display="flex" flex={1}>
        <SideMenuComponent />
        {formattedOngoingCrisis && (
          <PageContainer customClasses={containerClass}>
            <Button
              children={'Back'}
              variant="secondary"
              onClick={() => navigate('/ongoing-crisis')}
              className="back-button"
            />
            <CrisisDetailsCard crisisDetails={formattedOngoingCrisis} />
          </PageContainer>
        )}
        {showToast(currentError)}
      </Box>
    </Container>
  );
};

export default OngoingCrisisSubPage;
