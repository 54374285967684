import React from 'react';
import { PageCard } from 'supplier-portal-shared-components';
import useStyles from '../PageContainer/PageContainer.styles';

type IContainerSize = 'xl' | 'lg' | 'md';

export interface IPageContainer {
  customClasses?: string;
  children: React.ReactNode;
  fullscreen?: boolean;
  size?: IContainerSize;
}

function PageContainer({ customClasses, children, fullscreen, size }: IPageContainer): JSX.Element {
  const { container, fullscreenClass } = useStyles();

  return (
    <PageCard
      customClasses={`
        ${customClasses || ''} 
        ${container}
        ${fullscreen ? fullscreenClass : ''}
        ${size ? useStyles()[size] : ''}`}
    >
      {children}
    </PageCard>
  );
}

export default PageContainer;
