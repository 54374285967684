import { Box } from '@material-ui/core';
import Markdown from 'react-markdown';
import { Container } from 'supplier-portal-shared-components';
import { ICrisisFullDetailed } from '../../../interfaces/IOngoingCrisisDetails';
import useStyles from './CrisisDetailsCard.style';

interface ICrisisDetailsCardProps {
  crisisDetails: ICrisisFullDetailed;
}

const CrisisDetailsCard = ({ crisisDetails }: ICrisisDetailsCardProps): JSX.Element => {
  const { containerClass } = useStyles();

  return (
    <Container data-test="data-control-page-component" customClasses={containerClass}>
      <Box id={'ticketNumber'} key={'ticketNumber'} className={`fieldBox`}>
        <span className={`spanText`}>Incident Number:</span>
        {crisisDetails.ticketNumber}
      </Box>
      <Box id={'ticketSummary'} key={'ticketSummary'} className={`fieldBox`}>
        <span className={`spanText`}>Ticket Title:</span>
        {crisisDetails.ticketSummary}
      </Box>
      <Box id={'priority'} key={'priority'} className={`fieldBox`}>
        <span className={`spanText`}>Priority:</span>
        {crisisDetails.priority}
      </Box>
      <Box id={'status'} key={'status'} className={`fieldBox`}>
        <span className={`spanText`}>Status:</span>
        {crisisDetails.status}
      </Box>
      <Box id={'affectedCountry'} key={'affectedCountry'} className={`fieldBox`}>
        <span className={`spanText`}>Affected Countries:</span>
        {crisisDetails.affectedCountry?.join(', ') || ''}
      </Box>
      <Box id={'businessModels'} key={'businessModels'} className={`fieldBox`}>
        <span className={`spanText`}>Business Models:</span>
        {crisisDetails.businessModels?.join(', ') || ''}
      </Box>
      <Box id={'creationDateHour'} key={'creationDateHour'} className={`fieldBox`}>
        <span className={`spanText`}>Creation date:</span>
        {crisisDetails.creationDateHour}
      </Box>
      <Box id={'assignee'} key={'assignee'} className={`fieldBox`}>
        <span className={`spanText`}>Assignee:</span>
        {crisisDetails.assignee}
      </Box>
      <Box id={'ticketDescription'} key={'ticketDescription'} className={`fieldBox`}>
        <span className={`spanText`}>Ticket Description:</span>
        {crisisDetails.ticketDescription}
      </Box>
      <Box key={'timeline'} id={'timeline'} className={`fieldBox textCol`}>
        <span className={`spanText`}>Timeline:</span>
        {crisisDetails.timeline?.map((item, index) => (
          <Markdown className={`spanSpaced`} key={index} linkTarget={'_blank'}>
            {item}
          </Markdown>
        ))}
      </Box>
      <Box id={'engagedValueStreams'} key={'engagedValueStreams'} className={`fieldBox`}>
        <span className={`spanText`}>Engaged value streams:</span>
        {crisisDetails.engagedValueStreams?.join(', ')}
      </Box>
      <Box id={'statusCommunication'} key={'statusCommunication'} className={`fieldBox textCol`}>
        <span className={`spanText`}>Status Communication:</span>
        {crisisDetails.statusCommunication?.map((item, index) => (
          <p key={index} className={`spanSpaced`}>
            {item}
          </p>
        ))}
      </Box>
    </Container>
  );
};

export default CrisisDetailsCard;
