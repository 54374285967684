import React from 'react';
import availableCountries from '../../consts/availableCountries';
import UserInfoContext from '../../context/UserInfoContext';
import { ICountryOptions } from '../../interfaces/ICountry';
import CountrySelectView from './CountrySelectView';

export interface CountrySelectProps {
  countries?: ICountryOptions[];
}

function CountrySelect({ countries }: CountrySelectProps): JSX.Element {
  const countryOptions = countries ? countries : (availableCountries as ICountryOptions[]);

  const { selectedCountry, setUserInfoContext } = React.useContext(UserInfoContext);
  const [selectedValue, setSelectedValue] = React.useState<string>('');

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown; name?: string }>) => {
    const { value } = event.target;
    const foundCountry = availableCountries.find((country) => value === country.value);
    setSelectedValue(value as string);
    setUserInfoContext({ ...UserInfoContext, selectedCountry: foundCountry.value });
  };

  return (
    <CountrySelectView
      countryOptions={countryOptions}
      onSelectCountry={handleSelectChange}
      selectedCountry={selectedCountry || ''}
    />
  );
}

export default CountrySelect;
