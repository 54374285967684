import { Box, List, Typography } from '@material-ui/core';
import { Container, Title } from 'supplier-portal-shared-components';
import BeesOperationsGrayWings from '../../assets/BeesOperations_GrayWings.svg';
import { useEnvContext } from '../../components';
import PageContainer from '../../components/PageContainer/PageContainer';
import SideMenuComponent from '../../components/SideMenuComponent/SideMenuComponent';
import { formatMessage } from '../../i18n/formatters';
import { IWelcomeListItem } from './WelcomePage';
import useStyles from './WelcomePageView.styles';

export interface IWelcomePageViewProps {
  listItems: IWelcomeListItem[];
}

const WelcomePageView = ({ listItems }: IWelcomePageViewProps): JSX.Element => {
  const env = useEnvContext();

  const { titleClass, containerClass, listClass, listItemClass, welcomeClass } = useStyles();

  return (
    <Container data-test="data-control-page-component">
      <Title
        title={formatMessage({ id: 'WelcomePage.TITLE' })}
        data-test="data-welcome-page-title"
        variant="h2"
        customClasses={titleClass}
      />
      <Box display="flex" flex={1}>
        <SideMenuComponent />
        <PageContainer customClasses={containerClass}>
          <Typography variant="h2" className={welcomeClass}>
            {formatMessage({ id: 'WelcomePage.WELCOME_TITLE' })}
          </Typography>

          <Box mt="32px">
            <List className={listClass}>
              {listItems.map(
                (item) =>
                  item.isEnabled && (
                    <Typography key={item.id} className={listItemClass} data-testid={item.text}>
                      {item.text}
                    </Typography>
                  )
              )}
            </List>
          </Box>

          <Box display="flex" flex={1} flexDirection="column-reverse">
            <img src={BeesOperationsGrayWings} alt="Bees Sync Gray Wings" />
          </Box>
        </PageContainer>
      </Box>
    </Container>
  );
};

export default WelcomePageView;
