import { createStyles, makeStyles } from '@material-ui/core';

// istanbul ignore next
export default makeStyles((theme) =>
  createStyles({
    titleClass: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(10),
      paddingBottom: '20px',
      '& hr': {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.divider,
      },
      '& h2': {
        width: '1000px',
      },
    },
    containerClass: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '468px',
      maxWidth: '1200px',
      '& [class*="back-button"]': {
        alignSelf: 'flex-end',
      },
    },
  })
);
