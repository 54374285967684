import { Toast as HexaToast } from '@hexa-ui/components';
import { IToastType } from '../../interfaces/IToastType';

interface ToastProps {
  open: boolean;
  message: string;
  type: IToastType;
  onClose?: () => void;
}

export const Toast = (props: ToastProps) => (
  <HexaToast.Provider>
    <HexaToast.Root {...props} position="top-right" />
  </HexaToast.Provider>
);
