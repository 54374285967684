import React from 'react';
import { formatMessage } from '../../i18n/formatters';
import SimpleSelect, { SimpleSelectViewProps } from '../SimpleSelect/SimpleSelectView';
import useStyles from './ProductSelectView.styles';

export interface IProductSelectView {
  ProductSelectOptions: SimpleSelectViewProps['options'];
  onSelectProduct: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  selectedValue: string;
}

function ProductSelectView({
  ProductSelectOptions,
  onSelectProduct,
  selectedValue,
}: IProductSelectView): JSX.Element {
  const { selectClass } = useStyles();

  return (
    <SimpleSelect
      data-test="product-id-select"
      options={ProductSelectOptions}
      selectedValue={selectedValue}
      id="beesProductSelect"
      name="beesProductSelect"
      label={formatMessage({ id: 'Components.PRODUCT_ID_SELECT_LABEL' })}
      handleChange={onSelectProduct}
      placeholder="-"
      className={selectClass}
      canUpload={true}
      isHorizontal
    />
  );
}

export default ProductSelectView;
