import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  WelcomePage: {
    TITLE: 'Bees Operations',
    MENU_HELP: 'Help',
    WELCOME_TITLE: 'Welcome',
    DATA_MANAGEMENT_TEXT_ITEM: 'You can download or upload data on Bees app, in',
    MONITORING_TEXT_ITEM: 'You see all the data uploaded and check the errors in the',
    PAYLOAD_VALIDATOR_TEXT_ITEM:
      'In case you want to verify your code before you process it, access the',
    LINK_TITLE: 'Home',
    HOME: 'Home',
  },
  CRISIS_MANAGER_PAGE: {
    TITLE: 'Crisis Manager Page',
  },
  OPERATIONS_AUTOMATION_PAGE: {
    TITLE: 'Operations Automation Page',
  },
  RUNBOOK_AUTOMATION_PAGE: {
    TITLE: 'Runbook Automation Page',
  },
  ONGOING_CRISIS_PAGE: {
    TITLE: 'Ongoing Crisis Page',
  },
  TOOLING_MANAGEMENT: {
    TITLE: 'Tooling Management',
  },
  Components: {
    COUNTRY_INPUT_LABEL: 'Country:',
    PRODUCT_ID_SELECT_LABEL: 'Product',
    BUSINESS_MODEL_LABEL: 'Business Model',
  },
  CRISIS_MANAGEMENT: {
    TITLE: 'Crisis Management',
  },
  CRISIS_MANAGER: {
    TITLE: 'Crisis Manager',
  },
  ONGOING_CRISIS: {
    TITLE: 'Ongoing Crisis',
  },
  OPERATIONS_AUTOMATION: {
    TITLE: 'Operations Automation',
  },
  RUNBOOK_AUTOMATION: {
    TITLE: 'Runbook Automation',
  },
  Messages: {
    DEFAULT_ERROR: 'An internal problem has ocurred',
    NOT_FOUND: 'The resource you requested was not found',
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
};

export default enUS;
