import { ISideMenuItem } from 'supplier-portal-shared-components/dist/SideMenu/SideMenu.types';
import { formatMessage } from '../i18n/formatters';
import { NavigateToPath } from '../interfaces/INavigateToPath';

export function useSideBarMenuItems(navigateTo: NavigateToPath): ISideMenuItem[] {
  return [
    {
      id: 'tooling-management',
      isActive: false,
      label: formatMessage({ id: 'TOOLING_MANAGEMENT.TITLE' }),
      subItems: [
        {
          id: 'crisis-manager',
          isActive: true,
          label: formatMessage({ id: 'CRISIS_MANAGER.TITLE' }),
          link: '/crisis-manager',
          onClick: () => navigateTo('/crisis-manager'),
        },
        {
          id: 'operations-automation',
          isActive: true,
          label: formatMessage({ id: 'OPERATIONS_AUTOMATION.TITLE' }),
          link: '/operations-automation',
          onClick: () => navigateTo('/operations-automation'),
        },
        {
          id: 'runbook-automation',
          isActive: true,
          label: formatMessage({ id: 'RUNBOOK_AUTOMATION.TITLE' }),
          link: '/runbook-automation',
          onClick: () => navigateTo('/runbook-automation'),
        },
      ],
    },
    {
      id: 'crisis-management',
      isActive: false,
      label: formatMessage({ id: 'CRISIS_MANAGEMENT.TITLE' }),
      subItems: [
        {
          id: 'ongoing-crisis',
          isActive: true,
          label: formatMessage({ id: 'ONGOING_CRISIS.TITLE' }),
          link: '/ongoing-crisis',
          onClick: () => navigateTo('/ongoing-crisis'),
        },
      ],
    },
  ] as ISideMenuItem[];
}
