import { useNavigate } from 'react-router-dom';
import { SideMenu } from 'supplier-portal-shared-components';
import { useSideBarMenuItems } from '../../consts/sideBarMenuItems';
import { useSideMenuOptionItems } from '../../consts/sideMenuOptionsItems';
import useStyles from './SideMenuComponent.style';

function SideMenuComponent(): JSX.Element {
  const { navigateBarStyle } = useStyles();

  const navigate = useNavigate();
  return (
    <SideMenu
      onNavigate={function noRefCheck() {}}
      openedSideBarMobile={true}
      sideMenuItems={useSideBarMenuItems(navigate)}
      sideMenuOptionsItems={useSideMenuOptionItems(navigate)}
      customClasses={navigateBarStyle}
    />
  );
}

export default SideMenuComponent;
