import { Button, Input } from '@hexa-ui/components';
import { Box, List, Typography } from '@material-ui/core';
import React from 'react';
import { Container } from 'supplier-portal-shared-components';
import BeesOperationsGrayWings from '../../assets/BeesOperations_GrayWings.svg';
import BusinessModelSelect from '../../components/BusinessModelSelect/BusinessModelSelect';
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import PageContainer from '../../components/PageContainer/PageContainer';
import ProductSelect from '../../components/ProductSelect/ProductSelect';
import SideMenuComponent from '../../components/SideMenuComponent/SideMenuComponent';
import availableCountries from '../../consts/availableCountries';
import UserInfoContext from '../../context/UserInfoContext';
import { ICountryOptions } from '../../interfaces/ICountry';
import { ICrisisManager } from '../../interfaces/ICrisisManager';
import { IProduct } from '../../interfaces/IProduct';
import { businessModelMock } from '../../mocks/businessModelMock';
import { businesModelNamesMock } from '../../mocks/businessModelNamesMock';
import { crisisManagerMock } from '../../mocks/crisisManagerMock';
import useStyles from './CrisisManagerPageView.style';

const CrisisManagerPageView = (): JSX.Element => {
  const {
    containerClass,
    welcomeClass,
    listClass,
    listItemClass,
    listItemTextClass,
    cardTitleText,
  } = useStyles();

  const { selectedCountry, selectedProduct, setUserInfoContext, selectedBusinessModel } =
    React.useContext(UserInfoContext);
  const [crisisManagerObjectList, setCrisisManagerObjectList] = React.useState<ICrisisManager[]>(
    []
  );
  const [crisisManagerCountries, setCrisisManagerCountries] = React.useState<ICountryOptions[]>([]);
  const [crisisManagerObj, setCrisisManagerObj] = React.useState<ICrisisManager>();
  const [slackChannel, setSlackChannel] = React.useState<string>('-');
  const [shouldCancel, setShouldCancel] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<any>([]);
  const [inputValue, setInputValue] = React.useState<any>('');

  const getCrisisManagerInfos = (product: IProduct) => {
    setCrisisManagerObjectList(crisisManagerMock);
  };

  const getCountriesByCrisisManagerInfos = (crisisManagerInfos: ICrisisManager[]) => {
    const countries = crisisManagerInfos.map(({ name }) => name);
    const availableCountriesList = availableCountries.filter(({ label }) =>
      countries.includes(label)
    );
    setCrisisManagerCountries(availableCountriesList);
  };

  const getCountryBy = (abbreviation: string): ICountryOptions => {
    return availableCountries.find(({ value }) => value === abbreviation);
  };

  const getCrisisManagerObj = (country: string) => {
    const crisisManagerInfo = crisisManagerObjectList.find(({ name }) => name === country);
    setCrisisManagerObj(crisisManagerInfo);
    setShouldCancel(false);
  };

  const handleSlackChannelInput = (event) => {
    setSlackChannel(event.target.value);
  };

  const handleCancel = () => {
    setShouldCancel(true);
    setCrisisManagerCountries([]);
    setUserInfoContext({
      ...UserInfoContext,
      selectedBusinessModel: undefined,
      selectedCountry: undefined,
      selectedProduct: undefined,
    });
  };

  React.useEffect(() => {
    setUserInfoContext({
      selectedBusinessModel: undefined,
      selectedCountry: undefined,
      selectedProduct: undefined,
    });
  }, []);

  React.useEffect(() => {
    getCrisisManagerInfos(selectedProduct);
    getCountriesByCrisisManagerInfos(crisisManagerObjectList);
  }, [selectedProduct]);

  React.useEffect(() => {
    const country = getCountryBy(selectedCountry);
    getCrisisManagerObj(country && country.label);
    setSlackChannel(crisisManagerObj && crisisManagerObj.slackChannel);
    setValue(
      crisisManagerObj && crisisManagerObj.businessFlows.map((value) => ({ value, label: value }))
    );
  }, [selectedCountry, crisisManagerObj]);

  return (
    <Container data-test="data-control-page-component">
      <Box display="flex" flex={1}>
        <SideMenuComponent />
        <PageContainer customClasses={containerClass}>
          <Typography variant="h4" className={cardTitleText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt
          </Typography>
          <Typography variant="h2" className={welcomeClass}>
            <ProductSelect />
            <CountrySelect countries={crisisManagerCountries as ICountryOptions[]} />
            <BusinessModelSelect
              businessModels={businessModelMock}
              disabled={crisisManagerCountries.length === 0 || !selectedCountry}
            />
          </Typography>

          {selectedBusinessModel && !shouldCancel ? (
            <List className={listClass}>
              <Typography className={listItemClass}>
                Business Model Name:{' '}
                <span className="business-model-name-span">{businesModelNamesMock.name}</span>
              </Typography>
              <Box className={listItemClass} display="flex">
                Slack Channel:
                <Input
                  id={'slack-chanel'}
                  width="400px"
                  value={slackChannel}
                  onChange={(e) => handleSlackChannelInput(e)}
                />
              </Box>
              <Box className={listItemTextClass} display="flex">
                Business Flows:
                <MultiSelect
                  data-testid="multi-select-business"
                  id="codes"
                  setInputValue={setInputValue}
                  setValue={setValue}
                  inputValue={inputValue}
                  value={value}
                  placeholder="Business Flows"
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button children={'Save'} variant="primary" onClick={() => console.log('salvou')} />
                <Button children={'Cancel'} variant="secondary" onClick={handleCancel} />
              </Box>
            </List>
          ) : (
            <Box display="flex" flex={1}>
              <img src={BeesOperationsGrayWings} alt="Bees Operations Gray Wings" />
            </Box>
          )}
        </PageContainer>
      </Box>
    </Container>
  );
};

export default CrisisManagerPageView;
