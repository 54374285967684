import { ThemeOptions } from '@material-ui/core';

export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
} as ThemeOptions['breakpoints'];
