import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    container: {
      width: 'inherit',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xl')]: {
        maxWidth: '1280px',
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: '720px',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '700px',
      },
    },
    fullscreenClass: {
      width: '100%',
      maxWidth: 'inherit',
    },
    md: {
      maxWidth: '700px',
    },
    lg: {
      maxWidth: '720px',
    },
    xl: {
      maxWidth: '1280px',
    },
  })
);
