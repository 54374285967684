import { OnRowReturnType } from '@hexa-ui/components';
import { Box } from '@material-ui/core';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Spinner, Title } from 'supplier-portal-shared-components';
import Api from '../../Api/Api';
import { handleErrorMessage } from '../../Utils/handleError';
import SelectableTable from '../../components/SelectableTable/SelectableTable';
import SideMenuComponent from '../../components/SideMenuComponent/SideMenuComponent';
import { Toast } from '../../components/Toast/Toast';
import { formatMessage } from '../../i18n/formatters';
import { IOngoingCrisisDetails } from '../../interfaces/IOngoingCrisisDetails';
import { columnsMock } from '../../mocks/ongoingCrisisTableMock';
import useStyles from './OngoingCrisisPage.style';

const OngoingCrisisPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { titleClass } = useStyles();

  const [onGoingCrisisList, setOnGoingCrisisList] = React.useState<IOngoingCrisisDetails[]>([]);
  const [currentError, setCurrentError] = React.useState({ message: '' });
  const [isOpenToast, setIsOpenToast] = React.useState<boolean>(false);

  async function getOngoingCrisisList() {
    const { data, response: errorResponse } = await Api.get({
      url: `crisis/ongoing`,
    });

    if (data && !errorResponse) {
      setOnGoingCrisisList(data);
    } else {
      const errorMessage = handleErrorMessage(errorResponse.data.statusCode);
      setCurrentError({ message: errorMessage });
      setIsOpenToast(true);
      hideToast();
    }
  }

  React.useEffect(() => {
    getOngoingCrisisList();
  }, []);

  function hideToast() {
    setTimeout(() => {
      setIsOpenToast(false);
      navigate('/');
    }, 3000);
  }

  function showToast(currentError: any) {
    if (currentError && currentError.message.length > 0) {
      return <Toast message={currentError.message} open={isOpenToast} type={'error'} />;
    }
    return null;
  }

  function handleRowSelect(rowData: Record<string, unknown>, _rowIndex: number): OnRowReturnType {
    const rowAction: OnRowReturnType = {
      onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
        navigate(`/ongoing-crisis/${rowData.ticketNumber}`);
      },
    };
    return rowAction;
  }

  return (
    <Container data-test="data-control-page-component">
      {!onGoingCrisisList.length && <Spinner show dataTest="transition-spinner" />}
      <Title
        title={formatMessage({ id: 'ONGOING_CRISIS_PAGE.TITLE' })}
        data-test="data-ongoing-crisis-page-title"
        variant="h2"
        customClasses={titleClass}
      />
      <Box display="flex" flex={1} gridGap="50px">
        <SideMenuComponent />
        <SelectableTable
          loading={false}
          loadingMessage={''}
          pagination={{
            current: 1,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            showPageSizeSelector: true,
          }}
          columns={columnsMock}
          data={onGoingCrisisList}
          emptyMessage={'-'}
          selectable={{
            initialSelectedState: {},
            useCheckbox: false,
            displayTextOneRowSelected: '',
            displayTextMoreThanOneRowSelected: '',
            onDeselectAll: undefined,
          }}
          tableWidth="70%"
          onRow={handleRowSelect}
        />
        {showToast(currentError)}
      </Box>
    </Container>
  );
};

export default OngoingCrisisPage;
