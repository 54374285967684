import { ISideMenuOptionItem } from 'supplier-portal-shared-components/dist/SideMenu/SideMenu.types';
import { formatMessage } from '../i18n/formatters';
import { NavigateToPath } from '../interfaces/INavigateToPath';

export function useSideMenuOptionItems(navigateTo: NavigateToPath): ISideMenuOptionItem[] {
  return [
    {
      id: 'help',
      label: formatMessage({ id: 'WelcomePage.MENU_HELP' }),
    },
    {
      id: 'home',
      label: formatMessage({ id: 'WelcomePage.HOME' }),
      onClick: () => navigateTo('/'),
    },
  ] as ISideMenuOptionItem[];
}
