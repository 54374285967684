import { formatMessage } from '../../i18n/formatters';
import WelcomePageView from './WelcomePageView';

export interface IWelcomeListItem {
  id: number;
  text: string;
  isEnabled: boolean;
}

function WelcomePage(): JSX.Element {
  const listItems = [
    {
      id: 1,
      text: formatMessage({ id: 'WelcomePage.DATA_MANAGEMENT_TEXT_ITEM' }),
      isEnabled: true,
    },
    {
      id: 3,
      text: formatMessage({ id: 'WelcomePage.MONITORING_TEXT_ITEM' }),
      isEnabled: true,
    },
    {
      id: 4,
      text: formatMessage({ id: 'WelcomePage.PAYLOAD_VALIDATOR_TEXT_ITEM' }),
      isEnabled: true,
    },
  ] as IWelcomeListItem[];

  return <WelcomePageView listItems={listItems} />;
}

export default WelcomePage;
