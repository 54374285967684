import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { GenerateId } from 'jss';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { v4 as uuid } from 'uuid';
import Router from './Router';
import { EnvConfig, EnvProvider } from './components';
import { IUserInfoContext, UserInfoProvider } from './context/UserInfoContext';
import theme from './themes';

export interface EnvProps {
  optimizelyKey: string;
}

export default function App(props: EnvConfig) {
  const [userInfoContext, setInfoContext] = React.useState<
    IUserInfoContext | Record<string, unknown>
  >({});

  const generateClassName: GenerateId = (rule) => {
    return `bees-operations--${rule.key}-${uuid().split('-', 1)}`;
  };
  // istanbul ignore next
  const setUserInfoContext = (newEntry: Partial<IUserInfoContext>) => {
    setInfoContext((prevState) => ({
      ...prevState,
      ...newEntry,
    }));
  };

  return (
    <EnvProvider env={props}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StylesProvider generateClassName={generateClassName}>
          <UserInfoProvider value={{ ...userInfoContext, setUserInfoContext } as IUserInfoContext}>
            <IntlProvider locale="en-US">
              <Router />
            </IntlProvider>
          </UserInfoProvider>
        </StylesProvider>
      </ThemeProvider>
    </EnvProvider>
  );
}
