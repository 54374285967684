import React from 'react';
import { formatMessage } from '../../i18n/formatters';
import { ICountryOptions } from '../../interfaces/ICountry';
import useStyles from '../CountrySelect/CountrySelectView.styles';
import SimpleSelect from '../SimpleSelect/SimpleSelect';

export interface ICountrySelectView {
  countryOptions: ICountryOptions[];
  selectedCountry: string;
  onSelectCountry: (event: React.ChangeEvent<{ value: unknown; name?: string }>) => void;
}

function CountrySelectView({
  countryOptions,
  selectedCountry,
  onSelectCountry,
}: ICountrySelectView): JSX.Element {
  const { select } = useStyles();

  return (
    <SimpleSelect
      isHorizontal
      className={select}
      data-test="country-select"
      options={countryOptions}
      selectedValue={selectedCountry}
      disabled={countryOptions.length === 0}
      id="beesCountrySelect"
      name="beesCountrySelect"
      label={formatMessage({ id: 'Components.COUNTRY_INPUT_LABEL' })}
      handleChange={onSelectCountry}
      canUpload={true}
      placeholder="-"
    />
  );
}

export default CountrySelectView;
