import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    selectClass: {
      width: 'auto',
      '& > div': {
        minWidth: '150px',
        maxWidth: '212px',
      },
    },
  })
);
