import React from 'react';
import { formatMessage } from '../../i18n/formatters';
import SimpleSelect, { SimpleSelectViewProps } from '../SimpleSelect/SimpleSelectView';
import useStyles from './BusinessModelSelectView.styles';

export interface IBusinessModelSelectView {
  businessModelSelectOptions: SimpleSelectViewProps['options'];
  onSelectBusinessModel: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  selectedValue: string;
  disabled?: boolean;
}

function BusinessModelSelectView({
  businessModelSelectOptions,
  onSelectBusinessModel,
  selectedValue,
  disabled,
}: IBusinessModelSelectView): JSX.Element {
  const { selectClass } = useStyles();

  return (
    <SimpleSelect
      data-test="business-model-id-select"
      options={businessModelSelectOptions}
      selectedValue={selectedValue}
      id="beesBusinessModelSelect"
      name="beesBusinessModelSelect"
      label={formatMessage({ id: 'Components.BUSINESS_MODEL_LABEL' })}
      handleChange={onSelectBusinessModel}
      placeholder="-"
      className={selectClass}
      canUpload={true}
      isHorizontal
      disabled={disabled}
    />
  );
}

export default BusinessModelSelectView;
