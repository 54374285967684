import { Box, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Container, Title } from 'supplier-portal-shared-components';
import BeesOperationsGrayWings from '../../assets/BeesOperations_GrayWings.svg';
import PageContainer from '../../components/PageContainer/PageContainer';
import SideMenuComponent from '../../components/SideMenuComponent/SideMenuComponent';
import { formatMessage } from '../../i18n/formatters';
import useStyles from './RunbookAutomation.style';

const RunbookAutomationPage = (): JSX.Element => {
  const { titleClass, containerClass, welcomeClass } = useStyles();
  const navigate = useNavigate();

  return (
    <Container data-test="data-control-page-component">
      <Title
        title={formatMessage({ id: 'RUNBOOK_AUTOMATION_PAGE.TITLE' })}
        data-test="data-runbook-automation-page-title"
        variant="h2"
        customClasses={titleClass}
      />
      <Box display="flex" flex={1}>
        <SideMenuComponent />
        <PageContainer customClasses={containerClass}>
          <Typography variant="h2" className={welcomeClass}>
            {formatMessage({ id: 'WelcomePage.WELCOME_TITLE' })}
          </Typography>

          <Box mt="32px">Runbook Automation Page</Box>

          <Box display="flex" flex={1} flexDirection="column-reverse">
            <img src={BeesOperationsGrayWings} alt="Bees Sync Gray Wings" />
          </Box>
        </PageContainer>
      </Box>
    </Container>
  );
};

export default RunbookAutomationPage;
