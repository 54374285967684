import { createStyles, makeStyles } from '@material-ui/core';

// istanbul ignore next
export default makeStyles((theme) =>
  createStyles({
    containerClass: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minHeight: '468px',
      minWidth: '800px',
      gap: '10px',

      '& [class*="fieldBox"]': {
        display: 'flex',
        flex: 1,
      },
      '& [class*="spanText"]': {
        fontWeight: '600',
        marginRight: '10px',
      },
      '& [class*="textCol"]': {
        margin: '10px 0 0 0',
        display: 'block',
      },
      '& [class*="spanSpaced"]': {
        paddingLeft: '10px',
      },
      '& [class*="timelineText"]': {
        overflowWrap: 'anywhere',
      },
    },
  })
);
