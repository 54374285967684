import React from 'react';
import UserInfoContext from '../../context/UserInfoContext';
import { IBusinessModel } from '../../interfaces/IBusinessModel';
import { SimpleSelectViewProps } from '../SimpleSelect/SimpleSelectView';
import BusinessModelSelectView from './BusinessModelSelectView';

export interface BusinessModelSelectProps {
  businessModels?: IBusinessModel[];
  disabled?: boolean;
}

function BusinessModelSelect({ businessModels, disabled = null }): JSX.Element {
  const { selectedBusinessModel, setUserInfoContext } = React.useContext(UserInfoContext);
  const [selectedValue, setSelectedValue] = React.useState<string>('');
  const [businessModelSelectOptions, setBusinessModelOptions] = React.useState<
    SimpleSelectViewProps['options']
  >([]);

  // istanbul ignore next
  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown; name?: string }>) => {
    const { value } = event.target;
    const foundBusinessModel = businessModels.find((businessModel) => value === businessModel.id);
    setSelectedValue(value as string);
    setUserInfoContext({ ...UserInfoContext, selectedBusinessModel: foundBusinessModel });
  };

  // istanbul ignore next
  React.useEffect(() => {
    const businessModelSelectNewOptions = businessModels.map((businessModel) => {
      return {
        label: businessModel.name,
        value: businessModel.id,
      };
    });
    setBusinessModelOptions(businessModelSelectNewOptions);
    setSelectedValue(selectedBusinessModel?.id || '');
  }, [selectedBusinessModel]);

  return (
    <BusinessModelSelectView
      businessModelSelectOptions={businessModelSelectOptions}
      onSelectBusinessModel={handleSelectChange}
      selectedValue={selectedValue}
      disabled={disabled}
    />
  );
}

export default BusinessModelSelect;
