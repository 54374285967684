import { OnRowReturnType, Table } from '@hexa-ui/components';

type Selectable =
  | {
      initialSelectedState?: { [key: string]: boolean };
      useCheckbox?: boolean;
      displayTextOneRowSelected?: string | undefined;
      displayTextMoreThanOneRowSelected?: string | undefined;
      onDeselectAll?: (() => void) | undefined;
    }
  | undefined;

type Pagination = {
  current: number;
  pageSize: number;
  pageSizeOptions: number[];
  showPageSizeSelector: boolean;
};

export interface ISelectableTable {
  loading: boolean;
  loadingMessage: string;
  pagination: Pagination;
  columns: unknown[];
  data: unknown[];
  emptyMessage: string;
  selectable: Selectable;
  onRow: (rowData: Record<string, unknown>, rowIndex: number) => OnRowReturnType;
  tableWidth?: string;
  tableHeight?: string;
}

function SelectableTable({
  loading,
  loadingMessage,
  pagination,
  columns,
  data,
  emptyMessage,
  selectable,
  onRow,
  tableWidth,
  tableHeight,
}: ISelectableTable): JSX.Element {
  return (
    <Table
      loading={loading}
      loadingMessage={loadingMessage}
      pagination={pagination}
      columns={columns}
      data={data as unknown as Record<string, unknown>[]}
      emptyMessage={emptyMessage}
      selectable={selectable}
      onRow={onRow}
      tableWidth={tableWidth}
      tableHeight={tableHeight}
    />
  );
}

export default SelectableTable;
